<script>
import { app } from '@/main'
import { mapActions } from 'vuex'
import { services } from '../services'

import { HEADER_TAB_LINKS } from '../../../constants'

const TRANSLATION = {
  header: {
    title: app.$t('notifications:header.title'),
    description: app.$t('notifications:header.description')
  },

  createPlan: app.$t('notifications.mediation-plan:list.create-plan'),

  thead: {
    name: app.$t('notifications.mediation-plan:list.thead.name'),
    applicability: app.$t('notifications.mediation-plan:list.thead.applicability'),
    dispatchTime: app.$t('notifications.mediation-plan:list.thead.dispatchTime'),
    linkeds: app.$t('notifications.mediation-plan:list.thead.linkeds'),
    status: app.$t('notifications.mediation-plan:list.thead.status')
  },

  status: {
    active: app.$t('global.status:active'),
    inactive: app.$t('global.status:inactive')
  },

  verticalDots: {
    edit: app.$t('global:edit'),
    audit: app.$t('notifications.mediation-plan:audit.audit'),
    activate: app.$t('global:activate'),
    deactivate: app.$t('global:deactivate'),
    linked: app.$t('global:links')
  },

  feedback: {
    activateSuccess: app.$t('notifications.mediation-plan:list.feedback.toggle.activate.success'),
    deactivateSuccess: app.$t('notifications.mediation-plan:list.feedback.toggle.deactivate.success'),
    activateError: app.$t('notifications.mediation-plan:list.feedback.toggle.activate.error'),
    deactivateError: app.$t('notifications.mediation-plan:list.feedback.toggle.deactivate.error')
  }
}

const BACKGROUND = '/assets/images/themes/default/png/management_banner.png'

const ROUTES = {
  manage: 'notifications.mediation-plan.manage',
  audit: 'notifications.mediation-plan.audit'
}

export default {
  name: 'ListPage',

  components: {
    Action: () => import('@/components/general/Action'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Datatable: () => import('@/components/general/Datatable'),
    Dropdown: () => import('@/components/general/Dropdown'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    FilterList: () => import('@/components/general/FilterList'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    Pagination: () => import('@/components/general/Pagination'),
    Tabs: () => import('@/components/general/Tabs')
  },

  data () {
    return {
      mediationPlanList: [],
      mediationPlanListPagination: {
        query: {},
        filter: {},

        order: {
          active: 'desc',
          name: 'asc',
          created_at: 'desc'
        },

        limit: 12,
        page: 1,
        lastPage: null
      }
    }
  },

  computed: {
    hasMediationPlans () {
      return this.mediationPlanList.length > 0
    },

    canWrite () {
      return this.getContextPermission('mediation_plans') === 'write'
    }
  },

  watch: {
    'mediationPlanListPagination.page' () {
      this.fetchMediationPlansList()
    }
  },

  created () {
    this.setup()
    this.fetchMediationPlansList()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'setPagination'
    ]),

    setup () {
      this.HEADER_TAB_LINKS = HEADER_TAB_LINKS
      this.TRANSLATION = TRANSLATION
      this.BACKGROUND = BACKGROUND
      this.ROUTES = ROUTES
    },

    getActiveStatusText (isActive) {
      return isActive ? TRANSLATION.status.active : TRANSLATION.status.inactive
    },

    getActiveButtonText (isActive) {
      return isActive ? TRANSLATION.verticalDots.deactivate : TRANSLATION.verticalDots.activate
    },

    createMediationPlan () {
      this.$router.push({ name: ROUTES.manage })
    },

    searchMediationPlan (search) {
      this.mediationPlanListPagination.query = {
        name: search
      }

      const inFirstPage = this.mediationPlanListPagination.page === 1

      if (inFirstPage) {
        this.fetchMediationPlansList()

        return
      }

      this.mediationPlanListPagination.page = 1
    },

    edit (id) {
      this.$router.push({
        name: ROUTES.manage,
        params: {
          id: id
        }
      })
    },

    toLinked (id) {
      this.$router.push({
        name: ROUTES.manage,
        params: {
          id: id,
          page: 3
        }
      })
    },

    audit (id) {
      this.$router.push({
        name: ROUTES.audit,
        params: {
          id: id
        }
      })
    },

    toggle (mediationPlanId, toggleFlag) {
      this.setFetching(true)

      services.attemptToggleActiveMediationPlan(mediationPlanId)
        .then(() => {
          if (!toggleFlag) {
            this.setFeedback({
              message: TRANSLATION.feedback.activateSuccess
            })

            return
          }

          this.setFeedback({
            message: TRANSLATION.feedback.deactivateSuccess
          })
        })
        .catch(() => {
          if (!toggleFlag) {
            this.setFeedback({
              message: TRANSLATION.feedback.activateError
            })

            return
          }

          this.setFeedback({
            message: TRANSLATION.feedback.deactivateError
          })
        })
        .finally(() => {
          this.setFetching(false)
          this.fetchMediationPlansList()
        })
    },

    fetchMediationPlansList () {
      this.setFetching(true)

      services.attemptGetMediationPlanList(this.mediationPlanListPagination)
        .then(response => {
          this.mediationPlanListPagination.lastPage = response.lastPage
          this.mediationPlanList = response.data
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    firstPage () {
      this.mediationPlanListPagination.page = 1
    },

    lastPage () {
      this.mediationPlanListPagination.page = this.mediationPlanListPagination.lastPage
    },

    prevPage () {
      this.mediationPlanListPagination.page--
    },

    nextPage () {
      this.mediationPlanListPagination.page++
    },

    getDispatchTime (dispatchTime) {
      if (!dispatchTime) return null
      let formattedDispatchTime = ''

      if (dispatchTime < 10) {
        formattedDispatchTime += '0'
      }

      formattedDispatchTime += `${dispatchTime}:00`

      return formattedDispatchTime
    }
  }

}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="TRANSLATION.header.title"
      :description="TRANSLATION.header.description"
      :background="BACKGROUND"
    >
      <Tabs
        slot="tabs"
        :links="HEADER_TAB_LINKS"
        dark
      />
    </ContentHeader>

    <FilterList>
      <Action
        slot="button"
        :text="TRANSLATION.createPlan"
        primary
        large
        fixed-width
        type="button"
        @click="createMediationPlan()"
      />

      <FilterListSearch
        slot="search"
        :hide-error-details="true"
        @search="searchMediationPlan"
      />
    </FilterList>

    <div class="center notifications-content">
      <div
        v-if="hasMediationPlans"
        class="center"
      >
        <Datatable :items="mediationPlanList">
          <template slot="thead">
            <tr>
              <th
                class="th"
                width="300"
              >
                {{ TRANSLATION.thead.name }}
              </th>
              <th
                class="th text-center"
                width="90"
              >
                {{ TRANSLATION.thead.applicability }}
              </th>
              <th
                class="th text-center"
                width="20"
              >
                {{ TRANSLATION.thead.dispatchTime }}
              </th>
              <th
                class="th text-center"
                width="90"
              >
                {{ TRANSLATION.thead.linkeds }}
              </th>
              <th
                class="th text-center"
                width="60"
              >
                {{ TRANSLATION.thead.status }}
              </th>
              <th
                class="th text-center"
                width="40"
              />
            </tr>
          </template>

          <template
            slot="tbody"
            slot-scope="props"
          >
            <tr>
              <td class="td">
                <span class="td-text">{{ props.item.name }}</span>
              </td>

              <td class="td text-center">
                <span class="td-text">{{ props.item.metadata.contextDescription }}</span>
              </td>

              <td class="td text-center">
                <span class="td-text">{{ getDispatchTime(props.item.dispatchTime) }}</span>
              </td>

              <td class="td text-center">
                <span class="td-text">{{ props.item.metadata.totalLinked }}</span>
              </td>

              <td class="td text-center">
                <span
                  class="td-tag"
                  :class="!!props.item.active ? 'active-ribbon' : 'inactive-ribbon'"
                >
                  {{ getActiveStatusText(props.item.active) }}
                </span>
              </td>

              <td
                class="td td-actions"
                width="40"
              >
                <Dropdown
                  icon="dots-vertical"
                  right
                >
                  <DropdownLink
                    v-if="canWrite"
                    :text="TRANSLATION.verticalDots.edit"
                    @click="edit(props.item.id)"
                  />

                  <DropdownLink
                    :text="TRANSLATION.verticalDots.linked"
                    @click="toLinked(props.item.id)"
                  />

                  <DropdownLink
                    :text="TRANSLATION.verticalDots.audit"
                    @click="audit(props.item.id)"
                  />

                  <DropdownLink
                    v-if="canWrite"
                    :text="getActiveButtonText(props.item.active)"
                    @click="toggle(props.item.id, props.item.active)"
                  />
                </Dropdown>
              </td>
            </tr>
          </template>
        </Datatable>

        <Pagination
          :active-page="mediationPlanListPagination.page"
          :page-count="mediationPlanListPagination.lastPage"
          @firstPage="firstPage()"
          @lastPage="lastPage()"
          @nextPage="nextPage()"
          @previousPage="prevPage()"
        />
      </div>
    </div>
  </div>
</template>
